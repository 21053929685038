body {
	font-family: 'Open Sans', sans-serif;
	color: #444;
	font-size: 1rem;
	line-height: 1.5em;
	font-weight: 400;
}

p {
	margin-top: 0em;
	margin-bottom: 0.75em;
}

a {
	-webkit-transition: all 250ms ease;
	transition: all 250ms ease;
	color: #1e68bd;
	text-decoration: none;
	border-bottom: 1px solid rgba(3, 56, 108, 0.15);
	cursor: pointer;
}

a:hover {
	color: #09141b;
}

ul {
	margin-top: 0.5em;
	margin-bottom: 0.75em;
	padding-left: 1.25em;
}

ol {
	margin-top: 0.5em;
	margin-bottom: 0.75em;
	padding-left: 1.125em;
}

li {
	margin-bottom: 0.25em;
}

.cms_label,
label {
	margin-bottom: 0.25em;
	line-height: 1.25em;
	font-weight: 700;
}

label {
	display: block;
}

strong {
	font-weight: 700;
	letter-spacing: 0.02em;
}

em {
	font-style: italic;
	letter-spacing: 0.02em;
}

blockquote {
	margin-top: 1em;
	margin-bottom: 1em;
	padding: 1em 1.25em;
	border-left: 5px solid #54c4c4;
	background-color: rgba(84, 196, 196, 0.15);
	font-size: 1.1875em;
	line-height: 1.5em;
}

.primary,
.primary-button {
	font-family: 'Open Sans', sans-serif;
	display: inline-block;
	margin-top: 0.5em;
	margin-bottom: 0.5em;
	padding: 0.625em 1.75rem;
	border: 0;
	border-radius: 0em;
	background-color: #538300;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	color: #fff;
	font-size: 0.9rem;
	font-weight: 600;
	text-align: center;
	line-height: 1.25rem;
	letter-spacing: 0em;
	text-decoration: none;
	cursor: pointer;
}

.primary:hover,
.primary-button:hover {
	background-color: #477001;
	color: #fff;
}

.primary.header-search-button,
.primary-button.header-search-button {
	width: 3rem;
	height: 2.25rem;
	margin-top: 0em;
	margin-bottom: 0em;
	padding-top: 0em;
	padding-bottom: 0em;
	float: right;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
	border-left: 1px solid rgba(40, 46, 51, 0.1);
	border-radius: 0em 100px 100px 0em;
	background-color: hsla(0, 0%, 100%, 0.15);
	background-image: var(--search-blk);
	background-position: 45% 50%;
	background-size: 1em 1em;
	background-repeat: no-repeat;
	color: transparent;
	font-size: 0.8125rem;
	line-height: 1em;
	text-transform: uppercase;
}

.primary.header-search-button:hover,
.primary-button.header-search-button:hover {
	background-color: transparent;
}

.partners-container {
	display: block;
	max-width: 1300px;
	padding-top: 30px;
	padding-right: 30px;
	padding-left: 30px;
}

.partners-container.partners {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 0px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.nav-section {
	position: fixed;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: auto;
	border-bottom: 1px none transparent;
	background-color: #dfdfdf;
	box-shadow: none;
}

.footer-section {
	position: relative;
	padding-top: 2rem;
	border-top: 6px none #ffe674;
	background-color: #fff;
	color: hsla(0, 0%, 100%, 0.5);
	font-size: 0.875rem;
	line-height: 1.375rem;
}

.row.footer-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.footer-signoff-section {
	margin-top: 1rem;
	padding-top: 1.5rem;
	padding-bottom: 0rem;
	background-color: transparent;
	font-size: 0.75rem;
	line-height: 1rem;
}

.copyright-text {
	display: inline-block;
	margin-right: 0.5rem;
	margin-left: 0rem;
	color: #282e33;
}

.footer-signoff-list {
	display: inline-block;
	margin: 0rem;
	padding-left: 0px;
}

.footer-signoff-list-item {
	display: inline-block;
	margin-bottom: 0em;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
	border-left: 1px solid #000;
}

.left-footer-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: none;
	margin-right: 1.25rem;
	margin-bottom: 0em;
	padding-right: 0rem;
	clear: both;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
}

.left-footer-wrapper.logo-col {
	margin-right: 3rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
}

.footer-column-title {
	font-family: 'Open Sans', sans-serif;
	margin-top: 0em;
	color: #1f79be;
	font-size: 1.125rem;
	letter-spacing: 0em;
	font-weight: 600;
	margin-bottom: 0.5em;
}

.header-content-right-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 0em;
	padding-bottom: 0rem;
	float: right;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
}

.header-contents-wrap {
	position: relative;
	border-bottom: 1px none rgba(0, 0, 0, 0.05);
	background-color: #fff;
}

.breadcrumb-list {
	position: relative;
	left: 0px;
	right: 0px;
	bottom: auto;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin: 0em 0.125rem 0.5em;
	padding-left: 0px;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	color: #fff;
	font-size: 0.6875rem;
	line-height: 1em;
	text-transform: uppercase;
}

.breadcrumb-list-item {
	margin-top: 0.25rem;
	margin-right: 0.5rem;
	margin-bottom: 0.25rem;
	float: left;
}

.cms_field textarea,
.cms_field select,
.cms_field input,
.form-field {
	position: relative;
	width: auto;
	margin-bottom: 0.75em;
	padding: 4px 10px;
	font-size: 0.875em;
	border: 1px solid #cccccc;
}

.form-field.header-search-field {
	width: 105px;
	height: 2.25rem;
	margin-bottom: 0em;
	padding-right: 1.25em;
	padding-left: 1.5em;
	float: left;
	-webkit-box-ordinal-group: 0;
	-webkit-order: -1;
	-ms-flex-order: -1;
	order: -1;
	border: 0px none transparent;
	border-radius: 100px 0px 0px 100px;
	background-color: hsla(0, 0%, 100%, 0.15);
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
	color: #fff;
	font-size: 0.8125em;
}

.form-field.header-search-field:focus {
	width: 180px;
	border: 0px none transparent;
	background-color: transparent;
	color: #373a3d;
}

.secondary-nav-list {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0em;
	margin-bottom: 0rem;
	padding-left: 0rem;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	font-size: 1rem;
	line-height: 1.25rem;
}

.breadcrumb-divider {
	margin-left: 0.5rem;
	float: right;
	color: #373a3d;
}

.inside-row-nested {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.right-sidebar-column {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.left-nav-list {
	margin-top: 0em;
	margin-bottom: 1.5em;
	padding-left: 0px;
	box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.05);
}

.left-nav-list-item {
	margin-bottom: 1px;
	padding-top: 2px;
	padding-bottom: 2px;
	box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.05);
}

.left-nav-list-link {
	display: block;
	padding: 0.75em 1.5rem 0.75em 0.25rem;
	color: #373a3d;
	font-weight: 400;
}

.left-nav-list-link:hover {
	padding-right: 1rem;
	padding-left: 0.75rem;
	box-shadow: inset 3px 0 0 0 #1f79be;
	color: #1f79be;
}

.left-nav-list-link.w--current {
	padding-right: 0.75rem;
	padding-left: 1rem;
	background-color: hsla(0, 0%, 87.3%, 0.1);
	box-shadow: inset 3px 0 0 0 #1f79be;
	color: #1f79be;
	font-weight: 400;
}

.left-nav-list-link.w--current:hover {
	padding-right: 0.5rem;
	padding-left: 1.25rem;
}

.left-nav-nested-list {
	margin-top: 2px;
	margin-bottom: -2px;
	padding: 0em 0em 0em 1rem;
	border-top: 3px none #f0f0f0;
}

.left-nav-nested-list-item {
	margin-bottom: 1px;
	padding-top: 2px;
	padding-bottom: 2px;
	box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.05);
}

.left-nav-nested-list-link {
	display: block;
	padding: 0.75em 1.5rem 0.75em 0rem;
	color: #03386c;
	font-size: 0.8125em;
	line-height: 1.375em;
	font-weight: 400;
}

.left-nav-nested-list-link:hover {
	padding-right: 0.75rem;
	padding-left: 0.75rem;
	box-shadow: inset 3px 0 0 0 #1f79be;
	color: #1f79be;
}

.left-nav-nested-list-link.w--current {
	margin-bottom: 4px;
	padding-left: 0.75rem;
	background-color: hsla(0, 0%, 87.3%, 0.1);
	box-shadow: inset 3px 0 0 0 #1f79be;
	color: #1f79be;
	font-weight: 400;
}

.left-nav-nested-list-link.w--current:hover {
	padding-right: 0.5rem;
	padding-left: 1rem;
	color: #1e68bd;
}

.sidebar-section-wrap {
	position: relative;
	margin-bottom: 2.441em;
	margin-left: 1em;
	padding-top: 0.75em;
	border-top: 1px solid rgba(0, 0, 0, 0.05);
	font-size: 0.875rem;
	line-height: 1.5em;
}

.sidebar-section-title {
	margin-top: 0.25em;
	margin-bottom: 0.75em;
	font-family: EB Garamond, sans-serif;
	color: #373a3d;
	font-size: 1.4em;
	line-height: 1.3em;
	font-weight: 400;
}

.sidebar-list {
	padding-left: 0em;
}

.sidebar-list-item {
	margin-bottom: 0.75em;
	padding-left: 0px;
}

.section-wrap.inside-content-section {
	padding-right: 0vw;
	padding-left: 0vw;
	background-color: rgba(91, 99, 107, 0.03);
	background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, 0.75)), to(hsla(0, 0%, 100%, 0.75))), var(--blue-stripes);
	background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.75), hsla(0, 0%, 100%, 0.75)), var(--blue-stripes);
	background-position: 0px 0px, 0px 0px;
	background-size: auto, 50px;
}

.home-section {
	position: relative;
	z-index: 100;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	max-width: none;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.home-content-box-wrap {
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-webkit-flex-basis: 25%;
	-ms-flex-preferred-size: 25%;
	flex-basis: 25%;
}

.home-content-box-wrap.home-news-cbox {
	display: flex;
	width: 100%;
	max-width: 1200px;
	justify-content: space-between;
	align-items: flex-start;
}

.home-content-box-wrap.home-news-cbox.top-half {
	align-items: center;
}

.home-content-box-wrap.home-news-cbox.bottom-half {
	align-items: flex-start;
}

.home-content-box-title {
	margin-top: 0rem;
	margin-bottom: 0.25rem;
	font-family: EB Garamond, sans-serif;
	color: #1f79be;
	font-size: 2.25rem;
	line-height: 2rem;
	font-weight: 400;
	letter-spacing: 0em;
	text-transform: none;
}

.home-content-box-title.giveaways {
	margin-bottom: 1rem;
	color: #1f79be;
	text-align: center;
}

.hero-image {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	display: block;
	overflow: hidden;
	width: 100%;
	height: 100%;
	margin-right: auto;
	margin-left: auto;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.hero-image.banner {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-position: 0px 0px, 100% 32%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.inside-page-header-content-wrap {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 1rem;
	padding-top: 0.5em;
	padding-bottom: 0.5em;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.inside-page-header-section {
	position: relative;
	display: none;
	min-height: 300px;
	margin-right: -2vw;
	margin-bottom: 100px;
	margin-left: -2vw;
	background-color: #172a38;
}

.inside-content-beckground {
	position: relative;
	display: block;
	max-width: 1300px;
	margin-top: 175px;
	margin-right: auto;
	margin-left: auto;
	padding-top: 0rem;
	padding-bottom: 4rem;
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	background-color: #fff;
}

.main-content-wrap.nested-content-wrap {
	display: block;
	margin-right: 0.5em;
	padding-top: 0rem;
}

.intro-text-style {
	margin-bottom: 1.25em;
	color: #373a3d;
	font-size: 1.1875em;
	line-height: 1.5em;
	font-weight: 400;
}

.hero-image-title {
	max-width: none;
	margin-top: 0em;
	margin-bottom: 0em;
	font-family: EB Garamond, sans-serif;
	color: #ffe674;
	font-size: 3rem;
	line-height: 3rem;
	font-weight: 400;
	letter-spacing: 0em;
	text-transform: none;
	text-shadow: none;
}

.left-nav-list-content-wrap {
	display: block;
	overflow: hidden;
	height: auto;
	margin-top: 0rem;
	margin-right: 1em;
	padding-bottom: 3em;
	border-top: 1px none rgba(0, 0, 0, 0.03);
	border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.footer-link {
	color: #282e33;
}

.footer-link:hover {
	color: #282e33;
	text-decoration: underline;
}

.link {
	border-bottom: 1px none rgba(3, 56, 108, 0.25);
	color: #1f79be;
}

.link:hover {
	border-bottom-style: none;
	border-bottom-color: #beac71;
	color: #373a3d;
	text-decoration: underline;
}

.link.w--current {
	color: #28313b;
	font-weight: 700;
}

.link.breadcrumb-link {
	border-bottom-color: rgba(0, 0, 0, 0.15);
	color: #373a3d;
	font-size: 0.8rem;
	text-decoration: underline;
	text-transform: none;
}

.link.breadcrumb-link:hover {
	border-bottom-color: #1f79be;
	box-shadow: none;
	color: #1f79be;
}

.link.breadcrumb-link.w--current {
	color: #373a3d;
	font-size: 0.8rem;
	font-weight: 400;
	text-decoration: none;
}

.link.breadcrumb-link.w--current:hover {
	color: #1f79be;
	text-decoration: underline;
}

.link.home-news-article-title-link {
	border-bottom: 0.75px solid rgba(3, 56, 108, 0.25);
	font-family: EB Garamond, sans-serif;
	color: #03386c;
	line-height: 2.25rem;
	font-weight: 400;
	letter-spacing: 0em;
}

.link.home-news-article-title-link:hover {
	border-bottom-style: solid;
	border-bottom-color: transparent;
	box-shadow: none;
	color: #373a3d;
	text-decoration: none;
}

.md-doctor-form-reveal-wrap {
	display: none;
	overflow: hidden;
}

.divider {
	width: 1px;
	height: 475px;
	margin-top: 1.5em;
	margin-bottom: 1.5em;
	clear: both;
	background-color: rgba(0, 0, 0, 0.05);
}

.md-advanced-search-tooltip-popup {
	position: absolute;
	left: -1rem;
	bottom: 100%;
	display: none;
	width: 155px;
	margin-bottom: 0.5rem;
	padding: 0.75rem 1.125rem 1.125rem 0.875rem;
	border-radius: 3px;
	background-color: #28313b;
	box-shadow: 0 0 20px 5px rgba(40, 49, 59, 0.25);
	color: #fff;
	font-size: 0.8125em;
	line-height: 1.375em;
}

.md-patient-comments-reveal-more {
	overflow: hidden;
}

.shop-item-row-wrap-grid-view {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.loc-sidebar-form-reveal {
	overflow: hidden;
	height: auto;
}

.loc-refine-search-button-image {
	width: 0.75em;
	height: 0.75em;
	margin-top: 3px;
}

.pagination-view-by-button {
	width: 2.25rem;
	height: 2.25rem;
	margin-left: 0.5rem;
	border: 1px solid #000;
	opacity: 0.35;
}

.pagination-view-by-button:hover {
	opacity: 1;
}

.inside-page-content-wrap-no-sidebars {
	margin-right: 0%;
	margin-left: 0%;
}

.header-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	max-width: 1350px;
	padding: 0.6rem 2rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: transparent;
}

.shop-item-row-wrap-list-view {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.header-content-right-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
}

.secondary,
.secondary-button {
	font-family: 'Open Sans', sans-serif;
	margin: 0rem;
	padding: 0.625em 1.75rem;
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	border-radius: 0em;
	background-color: #1f79be;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
	color: #fff;
	font-size: 0.9rem;
	line-height: 1.25rem;
	font-weight: 600;
	letter-spacing: 0em;
	text-transform: capitalize;
	text-align: center;
}

.secondary:hover,
.secondary-button:hover {
	background-color: #1658a3;
	color: #fff;
}

.header-search-wrap {
	display: none;
	margin-bottom: 0px;
	margin-left: auto;
	border-style: solid;
	border-width: 1px;
	border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(40, 46, 51, 0.1);
	border-radius: 100px;
	background-color: #fff;
}

.header-search-form {
	display: block;
}

.winners-category-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.winners-category-item-abstract-wrap {
	margin-bottom: 1em;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-webkit-flex-basis: 100%;
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%;
}

.category-item-title-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-webkit-flex-basis: 100%;
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%;
}

.winners-category-item-text-wrap {
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}

.content-wrap {
	max-width: 1170px;
	margin-bottom: 2rem;
	padding-left: 2rem;
	background-color: transparent;
}

.home-intro-section {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	max-width: none;
	padding: 0rem 3rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border: 1px none #000;
	background-color: transparent;
}

.home-intro-section.news-and-winners {
	width: 100%;
	max-width: none;
	padding-top: 3rem;
	padding-bottom: 3rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border: 1px none #000;
	background-color: rgba(91, 99, 107, 0.03);
}

.header-social-icon-link {
	display: block;
	margin-right: 0rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-style: none;
	border-width: 2px;
	border-color: #1579be;
	border-radius: 100px;
	color: #fff;
	font-size: 0.6875rem;
	line-height: 1.125em;
	font-weight: 600;
}

.header-social-icon-link:hover {
	border-color: #fff;
	opacity: 1;
	-webkit-transform: scale(1.05);
	-ms-transform: scale(1.05);
	transform: scale(1.05);
	color: #fff;
}

.header-social-icon-link.facebook {
	margin-right: 0.5rem;
	border-style: none;
	border-color: #1579be;
	background-color: transparent;
}

.header-social-icon-link.facebook:hover {
	border-style: none;
	border-color: #fff;
	-webkit-transform: scale(1.05);
	-ms-transform: scale(1.05);
	transform: scale(1.05);
}

.header-social-icon-link.twitter {
	margin-right: 0.5rem;
}

.header-social-icon-image {
	width: 2.5rem;
	height: 2.5rem;
	margin-right: 0rem;
	padding: 0rem;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	border-style: none;
	border-width: 2px;
	border-color: #1579be;
	border-radius: 0%;
}

.home-news-article-title {
	margin-top: 0.25rem;
	margin-bottom: 0.5rem;
	color: #03386c;
	font-size: 1.5rem;
	line-height: 2rem;
}

.home-news-article-wrap {
	max-width: 100%;
	margin-top: 0.5em;
	margin-bottom: 0.75em;
	padding-bottom: 0em;
	-webkit-flex-basis: auto;
	-ms-flex-preferred-size: auto;
	flex-basis: auto;
	border-top: 1px none rgba(0, 0, 0, 0.15);
	border-bottom: 1px none rgba(0, 0, 0, 0.15);
}

.link.home-news-article-title-link {
	margin-top: 0;
}

.home-news-article-wrap h1,
.home-news-article-wrap h2,
.home-news-article-wrap h3,
.home-news-article-wrap h4,
.home-news-article-wrap h5,
.home-news-article-wrap h6 {
	margin-top: 0;
}

.intro-cbox-signature-label p,
.intro-cbox-signature-label {
	margin-bottom: 0rem;
	font-size: 0.75rem;
	line-height: 1rem;
	font-weight: 600;
	letter-spacing: 0.02em;
	text-transform: uppercase;
}

.footer-social-list-item {
	max-width: 110px;
	margin-top: 0rem;
	margin-bottom: 0rem;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	color: #fff;
}

.header-social-icon-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-left: auto;
}

.header-social-list {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0em;
	margin-bottom: 0em;
	padding-left: 0rem;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-align-content: center;
	-ms-flex-line-pack: center;
	align-content: center;
}

.header-social-list.active-giveaway {
	margin-right: 2rem;
}

.header-social-list-item {
	max-width: 110px;
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.hero-image-wrapper {
	position: relative;
	height: 60vh;
	margin-top: 170px;
	border: 1px none #000;
}

.hero-inner-content {
	position: relative;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 100%;
	max-width: 1350px;
	margin-left: 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.partners-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 300px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
}

.partners-block.ascensus {
	width: 300px;
}

.partners-wrapper {
	padding-top: 3rem;
	padding-bottom: 3rem;
	border-top: 1px none rgba(0, 0, 0, 0.1);
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.paragraph {
	margin-bottom: 0rem;
	color: #373a3d;
}

.treasurer-logo-img {
	margin-right: 1.5rem;
	opacity: 0.8;
}

.home-content-box {
	width: 48%;
	height: 475px;
	padding: 0rem;
	background-color: #fff;
}

.home-content-box.welcome {
	display: block;
	width: 48%;
	height: 450px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: transparent;
}

.home-content-box.winners {
	height: auto;
}

.home-content-box.news {
	height: auto;
}

.home-content-box.about {
	display: block;
	height: 450px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: transparent;
	background-image: none;
	background-position: 0px 0px;
	background-size: auto;
}

.dd-text {
	padding-right: 0.5rem;
	line-height: 1rem;
}

.nav-menu {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	max-width: none;
	margin-left: 0px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: stretch;
	-ms-flex-item-align: stretch;
	align-self: stretch;
}

.nav-dd {
	display: block;
	padding-bottom: 1px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: stretch;
	-ms-flex-item-align: stretch;
	-ms-grid-row-align: stretch;
	align-self: stretch;
	border-right: 1px none rgba(0, 0, 0, 0.05);
}

.dd-icon {
	position: static;
	bottom: 2px;
	display: block;
	margin-top: 0px;
	margin-right: 0px;
	margin-bottom: 0px;
	padding-right: 0rem;
	background-color: transparent;
	font-size: 0.7rem;
	line-height: 1rem;
}

.nav-toggle {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	margin-right: 0rem;
	margin-left: 0px;
	padding: 0.8rem 3.75rem 0.8rem 3.5rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	color: #373a3d;
	font-size: 1rem;
	font-weight: 400;
	letter-spacing: 0rem;
	text-transform: capitalize;
}

.nav-toggle:hover {
	color: #ae332a;
}

.nav-dd-list {
	overflow: hidden;
	height: auto;
	background-color: #fff;
	box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
	white-space: nowrap;
}

.nav-dd-list.w--open {
	margin-top: -3px;
	border-top: 5px solid #ae332a;
	background-color: #dfdfdf;
	box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.05);
}

.dd-link {
	color: #373a3d;
	font-weight: 400;
}

.dd-link:hover {
	color: #373a3d;
	text-decoration: none;
}

.nav-menu-link {
	padding: 0.8rem 3.5rem;
	color: #373a3d;
	font-size: 1rem;
	letter-spacing: 0em;
	text-transform: capitalize;
}

.nav-menu-link:hover a.main-nav-link.top-header-nav-link {
	flex: 0 0 auto;
	color: #ae332a;
}

.nav-menu-link.w--current {
	margin-bottom: -2px;
	border-bottom-style: none;
	border-bottom-color: #ae332a;
	color: #333;
}

.menu-container {
	display: flex;
	width: 100%;
	height: auto;
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
	justify-content: center;
	align-items: center;
	align-self: stretch;
	border-bottom: 1px none transparent;
}

.link-block {
	display: flex;
	margin-right: auto;
	align-items: center;
	border-bottom: none;
}

.div-block-8 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: auto;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	cursor: pointer;
}

.pagination-previous-page.pagination-bg,
.pagination-next-page.pagination-bg,
#news_module a[href="/news"],
.tertiary,
.tertiary-button {
	font-family: 'Open Sans', sans-serif;
	position: relative;
	bottom: 0px;
	display: inline-block;
	margin-top: 1rem;
	padding: 0px 0px 0rem;
	border-bottom: 1px solid #373a3d;
	background-color: transparent;
	color: #373a3d;
	font-size: 0.75rem;
	line-height: 1.25rem;
	font-weight: 600;
	text-transform: uppercase;
}

.pagination-previous-page.pagination-bg:hover,
.pagination-next-page.pagination-bg:hover,
#news_module a[href="/news"]:hover,
.tertiary:hover,
.tertiary-button:hover {
	border-bottom-color: #fff;
	color: #373a3d;
}

.content-image {
	height: 250px;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.content-wrapper {
	display: flex;
	padding: 2rem;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	border-top: 5px none #ffe674;
}

.top-half .content-wrapper {
	padding: 1rem;
}

.content-wrapper.giveaway {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 450px;
	padding-bottom: 1rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-top-style: none;
}

.bio-image {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100px;
	height: 100px;
	margin-top: 0rem;
	margin-right: 1rem;
	margin-bottom: 0rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-position: 0% 50%;
	background-size: contain;
	background-repeat: no-repeat;
}

.home-content {
	max-width: 100%;
	margin-top: 0.5em;
	margin-bottom: 0.75em;
	padding-bottom: 0em;
	-webkit-flex-basis: auto;
	-ms-flex-preferred-size: auto;
	flex-basis: auto;
	border-top: 1px none rgba(0, 0, 0, 0.15);
	border-bottom: 1px none rgba(0, 0, 0, 0.15);
}

.bio-img-wrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 24px;
}

.div-block-13 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0px;
	margin-right: 0rem;
	margin-bottom: 2rem;
	padding: 1rem;
	border-style: solid;
	border-width: 3px;
	border-color: #1f79be;
	border-radius: 100px;
	background-color: #1f79be;
}

.div-block-14 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.nav-menu-mobile-link {
	display: none;
}

.two-part-mobile-nav-wrapper {
	display: none;
}

.main-nav-link.w--current,
.main-nav-link {
	position: relative;
	display: block;
	padding: 0.5rem 1.25rem;
	-webkit-transition: all 150ms ease;
	transition: all 150ms ease;
	color: #373a3d;
	font-size: 1rem;
	line-height: 1.2rem;
	font-weight: 500;
	text-decoration: none;
	cursor: pointer;
}

.main-nav-link.mobile-duplicate {
	display: none;
}

.secondary-nav-list-item-2 {
	margin-bottom: 0em;
	float: left;
	border-left: 1px solid rgba(0, 0, 0, 0.2);
	letter-spacing: 0.01em;
}

.footer-social-list-item-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0em;
	margin-right: 0em;
	margin-bottom: 0em;
	padding-left: 0rem;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-align-content: center;
	-ms-flex-line-pack: center;
	align-content: center;
}

.image-3 {
	width: 125px;
}

.top-header-wrapper {
	position: relative;
	display: block;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	background-color: #333;
}

.links-wrap {
	position: relative;
	display: block;
	width: 100%;
	margin-right: 0rem;
	margin-left: 0px;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.top-header-link {
	color: #fff;
	font-size: 0.8rem;
	text-decoration: none;
}

.top-header-link:hover {
	color: #fff;
	text-decoration: underline;
}

.top-header-link.account {
	margin-right: 2rem;
	font-size: 0.8rem;
}

.top-header-link.account:hover {
	color: #fff;
	text-decoration: underline;
}

.top-header-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	max-width: 1350px;
	padding: 0.125rem 2rem;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: transparent;
}

.invisible-block {
	position: absolute;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: auto;
	height: 130px;
	background-color: transparent;
}

.phone-number {
	color: #282e33;
}

.phone-number:hover {
	text-decoration: underline;
}

.footer-rights-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.footer-rights-left-col {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.cb-image-wrapper {
	position: relative;
	overflow: hidden;
	width: 75%;
	height: 375px;
}

.cb-image-wrapper.inside-page {
	width: 100%;
	height: 250px;
}

.cb-image {
	height: 100%;
	background-position: 50% 30%;
	background-size: cover;
	background-repeat: no-repeat;
}

h1,
.h1-heading {
	margin-top: 0em;
	margin-bottom: 0.25em;
	font-family: EB Garamond, sans-serif;
	color: #1f79be;
	font-size: 2.25em;
	font-weight: 400;
	line-height: 1.375em;
	letter-spacing: 0.01em;
}

h2,
.h2-heading {
	margin-top: 0em;
	margin-bottom: 0em;
	font-family: EB Garamond, sans-serif;
	color: #1f79be;
	font-size: 1.875em;
	font-weight: 400;
	line-height: 1.375em;
	letter-spacing: 0.01em;
}

h2 .giveaway-headings,
.h2-heading.giveaway-headings {
	margin-top: 0.25em;
	color: #03386c;
}

h3,
.h3-heading {
	margin-top: 1em;
	margin-bottom: 0em;
	font-family: EB Garamond, sans-serif;
	color: #1f79be;
	font-weight: 400;
	font-size: 1.5em;
	line-height: 1.375em;
	letter-spacing: 0.01em;
}

h4,
.h4-heading {
	margin-bottom: 0em;
	font-family: EB Garamond, sans-serif;
	color: #1f79be;
	font-weight: 400;
	margin-top: 1em;
	font-size: 1.25em;
	line-height: 1.375em;
	letter-spacing: 0.01em;
}

h5,
.h5-heading {
	margin-bottom: 0em;
	font-family: EB Garamond, sans-serif;
	color: #1f79be;
	font-weight: 400;
	margin-top: 1.375em;
	font-size: 0.9375em;
	line-height: 1.5em;
	letter-spacing: 0.01em;
}

h6,
.h6-heading {
	margin-bottom: 0em;
	font-family: EB Garamond, sans-serif;
	color: #1f79be;
	line-height: 1.25em;
	font-weight: 400;
	margin-top: 1.5em;
	font-size: 0.8125em;
	letter-spacing: 0.01em;
}

.paragraph-style {
	color: #373a3d;
}

h1 a {
	font-size: 2.25rem;
	color: #03386c;
}

h1 a:hover {
	display: inline-block;
	border-bottom: 1px solid transparent;
	color: #373a3d;
	text-decoration: none;
}

h2 a,
.h2-heading-link {
	display: inline-block;
	margin-top: 0.125em;
	margin-bottom: 0.5em;
	border-bottom: 1px solid rgba(3, 56, 108, 0.25);
	font-family: EB Garamond, sans-serif;
	color: #03386c;
	font-size: 1.875rem;
	line-height: 1.25em;
	font-weight: 400;
}

h2 a:hover,
.h2-heading-link:hover {
	display: inline-block;
	border-bottom: 1px solid transparent;
	color: #373a3d;
	text-decoration: none;
}

h2 a.past-winners-title,
.h2-heading-link.past-winners-title {
	margin-bottom: 0.25em;
	border-bottom: 1px solid rgba(3, 56, 108, 0.25);
	line-height: 1.15em;
}

h2 a.past-winners-title:hover,
.h2-heading-link.past-winners-title:hover {
	border-bottom-color: transparent;
	text-decoration: none;
}

h3 a,
.h3-heading-link {
	display: inline-block;
	margin-top: 0.125em;
	margin-bottom: 0.5em;
	border-bottom: 1px solid rgba(3, 56, 108, 0.25);
	font-family: EB Garamond, sans-serif;
	color: #03386c;
	font-size: 1.5rem;
	line-height: 1.25em;
	font-weight: 400;
}

h3 a:hover,
.h3-heading-link:hover {
	display: inline-block;
	border-bottom: 1px solid transparent;
	color: #373a3d;
	text-decoration: none;
}

h4 a,
.h4-heading-link {
	display: inline-block;
	margin-top: 0.125em;
	margin-bottom: 0.5em;
	border-bottom: 1px solid rgba(3, 56, 108, 0.25);
	font-family: EB Garamond, sans-serif;
	color: #03386c;
	font-size: 1.25rem;
	line-height: 1.25em;
	font-weight: 400;
}

h4 a:hover,
.h4-heading-link:hover {
	display: inline-block;
	border-bottom: 1px solid transparent;
	color: #373a3d;
	text-decoration: none;
}

h5 a,
.h5-heading-link {
	display: inline-block;
	margin-top: 0.125em;
	margin-bottom: 0.5em;
	border-bottom: 1px solid rgba(3, 56, 108, 0.25);
	font-family: EB Garamond, sans-serif;
	color: #03386c;
	font-size: 0.937rem;
	line-height: 1.25em;
	font-weight: 400;
	text-decoration: none;
}

h5 a:hover,
.h5-heading-link:hover {
	display: inline-block;
	border-bottom: 1px solid transparent;
	color: #373a3d;
	text-decoration: none;
}

h6 a,
.h6-heading-link {
	display: inline-block;
	margin-top: 0.125em;
	margin-bottom: 0.5em;
	border-bottom: 1px solid rgba(3, 56, 108, 0.25);
	font-family: EB Garamond, sans-serif;
	color: #03386c;
	font-size: 0.8125rem;
	line-height: 1.25em;
	font-weight: 400;
}

h6 a:hover,
.h6-heading-link:hover {
	display: inline-block;
	border-bottom: 1px solid transparent;
	color: #373a3d;
	text-decoration: none;
}

blockquote,
.block-quote {
	margin-top: 20px;
	margin-bottom: 20px;
	border-left-color: #1658a3;
	background-color: rgba(91, 99, 107, 0.03);
	font-family: 'Open Sans', sans-serif;
	color: #373a3d;
	font-size: 1.15rem;
	line-height: 1.5rem;
	font-weight: 400;
}

.horizontal-divider {
	height: 1px;
	margin-top: 1.5rem;
	margin-bottom: 1rem;
	background-color: rgba(0, 0, 0, 0.05);
	color: #003c43;
}

.button-styling {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 500px;
	margin-bottom: 1rem;
	padding-left: 0px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	font-family: 'Open Sans', sans-serif;
	font-weight: 800;
}

.table-div {
	font-family: Montserrat, sans-serif;
	font-size: 0.8rem;
	font-weight: 400;
}

.past-winners-date {
	font-family: 'Open Sans', sans-serif;
	display: inline-block;
	margin-top: 0rem;
	margin-bottom: 0.25rem;
	padding: 0.25rem 0rem;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
	color: #ae332a;
	font-size: 0.8rem;
	line-height: 1.25em;
	font-weight: 700;
	text-transform: uppercase;
}

.pagination-wrapper {
	margin-top: 3rem;
	margin-bottom: 0rem;
	padding-top: 0.75em;
	padding-bottom: 0.75em;
	border-top: 1px solid rgba(0, 0, 0, 0.05);
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	font-size: 0.875em;
	line-height: 1.25em;
	text-align: center;
}

.text-link {
	border-bottom: 1px solid #c5c5c5;
	color: #ac162c;
	text-decoration: none;
}

.text-link:hover {
	border-bottom-style: none;
	border-bottom-color: #c3002f;
}

.text-link.w--current {
	color: #28313b;
	font-weight: 700;
}

.text-link.pagination-link {
	margin: 0.5em 0.25em;
	float: left;
	border-bottom-color: rgba(3, 56, 108, 0.25);
	opacity: 1;
	color: #373a3d;
	font-size: 0.75rem;
	line-height: 1rem;
	font-weight: 600;
	letter-spacing: 0px;
	text-decoration: none;
	text-transform: uppercase;
}

.text-link.pagination-link:hover {
	border-bottom-style: solid;
	border-bottom-color: #fff;
	opacity: 1;
	color: #231f20;
}

.text-link.pagination-link.next {
	float: right;
	border-bottom-color: rgba(3, 56, 108, 0.25);
}

.text-link.pagination-link.next:hover {
	border-bottom-color: transparent;
}

.pagination-middle-wrap-2 {
	display: inline-block;
	clear: both;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.pagination-middle-text-2 {
	display: inline-block;
	margin-right: 0.5em;
	margin-left: 0.5em;
	font-family: 'Open Sans', sans-serif;
	color: #373a3d;
}

.pagination-middle-select-2 {
	display: inline-block;
	width: auto;
	max-height: 2.25rem;
	margin-bottom: 0px;
	padding: 0px 0.125rem 0px 0.25rem;
	border-style: solid;
	border-width: 1px;
	border-color: rgba(0, 0, 0, 0.15);
	border-radius: 0px;
	background-color: rgba(77, 76, 76, 0.03);
	font-family: 'Open Sans', sans-serif;
	color: #373a3d;
}

.winners-name {
	color: #373a3d;
}

.category-media {
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.register-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0rem;
	padding-top: 2rem;
	padding-bottom: 0rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: transparent;
}

.official-rules-category-item-abstract-wrap {
	margin-bottom: 2rem;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-webkit-flex-basis: 100%;
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%;
}

.div-block-20 {
	margin-top: 40px;
}

.div-block-21 {
	margin-top: 40px;
}

.footer-container {
	display: block;
	max-width: 1300px;
	padding-top: 30px;
	padding-right: 30px;
	padding-left: 30px;
}

html.w-mod-js *[data-ix="md-patient-comments-reveal-on-load"] {
	height: 0px;
}

html.w-mod-js *[data-ix="for-two-part-button-list"] {
	-webkit-transform: translate(100%, 0px);
	-ms-transform: translate(100%, 0px);
	transform: translate(100%, 0px);
}

html.w-mod-js *[data-ix="display-none-on-page-load"] {
	display: none;
}

html.w-mod-js *[data-ix="for-grid-view-button"] {
	opacity: 0.7500000000000002;
}

html.w-mod-js *[data-ix="preloader-wrap"] {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

html.w-mod-js *[data-ix="hide-mobile-header-on-load"] {
	height: 0px;
}

.anchor-on-mobile {
	display: none;
}

.cms_metadata2.cms_date h3,
.home-news-article-date {
	font-family: 'Open Sans', sans-serif;
	display: inline-block;
	margin-top: 0rem;
	margin-bottom: 1.25rem;
	padding: 0.25rem 0rem;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
	color: #ae332a;
	font-size: 0.8rem;
	line-height: 1.25em;
	font-weight: 700;
	text-transform: uppercase;
}

@media screen and (max-width: 991px) {
	.anchor-on-mobile {
		display: block;
	}

	.span-on-desktop {
		display: none;
	}

	.nav-menu.w-nav-menu {
		display: none;
	}

	.w-nav-button {
		display: block;
	}

	h1 {
		font-size: 2em;
	}

	.primary,
	.primary-button {
		margin-top: 0;
		margin-bottom: 0;
	}

	.primary.header-search-button,
	.primary-button.header-search-button {
		width: 10%;
		height: 3.5rem;
		-webkit-box-flex: 1;
		-webkit-flex-grow: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		font-size: 1rem;
	}

	.partners-container {
		padding-top: 0;
		padding-right: 0;
		padding-left: 0;
	}

	.partners-container.main-content {
		padding-top: 30px;
	}

	.nav-section {
		border-bottom-color: rgba(0, 0, 0, 0.05);
	}

	.footer-section {
		padding: 3rem 2rem 1rem;
	}

	.row.footer-row {
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.footer-signoff-section {
		padding-top: 1rem;
		padding-bottom: 1rem;
		text-align: center;
	}

	.copyright-text {
		margin-bottom: 0.25rem;
		margin-left: 0;
	}

	.footer-signoff-list {
		margin-right: 0;
		margin-left: 0;
	}

	.footer-signoff-list-item {
		padding-left: 0.5rem;
	}

	.footer-signoff-list-item.adobe-acrobat {
		padding-left: 0;
		border-left-style: none;
	}

	.left-footer-wrapper {
		max-width: 50%;
		margin-right: 0;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.left-footer-wrapper.logo-col {
		max-width: 50%;
	}

	.header-content-right-wrap {
		margin-right: 0;
	}

	.form-field.header-search-field {
		width: 90%;
		height: 3.5rem;
		-webkit-box-flex: 90%;
		-webkit-flex: 90%;
		-ms-flex: 90%;
		flex: 90%;
		font-size: 0.9375em;
	}

	.form-field.header-search-field:focus {
		width: 90%;
	}

	.secondary-nav-list {
		margin-top: 0;
		margin-bottom: 0;
		margin-left: 2rem;
		-webkit-box-ordinal-group: 0;
		-webkit-order: -1;
		-ms-flex-order: -1;
		order: -1;
		border-radius: 0;
		background-color: transparent;
	}

	.right-sidebar-column {
		margin-top: 0;
	}

	.left-nav-list {
		margin-bottom: 1.5em;
	}

	.sidebar-section-wrap {
		margin-bottom: 0.25em;
		margin-left: 0;
	}

	.section-wrap.inside-content-section {
		padding-right: 0;
		padding-left: 0;
	}

	.home-content-box-wrap {
		width: 100%;
		margin-bottom: 1rem;
		padding-bottom: 1rem;
		float: left;
		-webkit-flex-basis: 50%;
		-ms-flex-preferred-size: 50%;
		flex-basis: 50%;
	}

	.home-content-box-wrap.home-news-cbox {
		margin: 0;
		padding-right: 0;
		padding-bottom: 0;
		padding-left: 0;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.home-content-box-title.giveaways {
		text-align: center;
	}

	.hero-image.banner {
		background-position: 0 0px, 100% 40%;
		background-size: auto, cover;
		background-repeat: repeat, no-repeat;
	}

	.inside-page-header-section {
		margin-right: -1vw;
		margin-left: -1vw;
	}

	.inside-content-beckground {
		padding-right: 2rem;
		padding-bottom: 2rem;
		padding-left: 2rem;
	}

	.main-content-wrap.nested-content-wrap {
		margin-right: 1.5rem;
	}

	.hero-image-title {
		max-width: none;
		font-size: 2.5rem;
		line-height: 2.5rem;
	}

	.left-nav-list-content-wrap {
		overflow: hidden;
		height: 0;
	}

	.md-doctor-form-reveal-wrap {
		display: none;
	}

	.divider {
		width: 100%;
		height: 1px;
		margin-top: 0;
		margin-bottom: 0;
	}

	.loc-sidebar-form-reveal {
		height: 0;
	}

	.inside-page-content-wrap-no-sidebars {
		margin-right: 0;
		margin-left: 0;
	}

	.two-part-button-list {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 150;
		display: block;
		width: 100%;
		margin-left: 100%;
		padding-bottom: 100vh;
		background-color: #172a38;
		background-image: var(--speckled-square);
		background-position: 50% 0;
		background-size: 50px;
	}

	.header-container {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}

	.header-content-right-row {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}

	.secondary,
	.secondary-button {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-left: 0;
	}

	.secondary.mobile,
	.secondary-button.mobile {
		display: none;
	}

	.header-search-wrap {
		margin-left: 0;
		padding: 1rem;
	}

	.header-search-form {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.header-mobile-search-reveal {
		overflow: hidden;
	}

	.content-wrap {
		margin-bottom: 1.5rem;
		padding-right: 2rem;
		padding-left: 2rem;
		text-align: center;
	}

	.home-intro-section {
		padding-top: 3rem;
		padding-bottom: 3rem;
	}

	.header-social-icon-image {
		width: 2.25rem;
		height: 2.5rem;
		padding: 0;
	}

	.home-news-article-wrap {
		max-width: 100%;
		-webkit-flex-basis: auto;
		-ms-flex-preferred-size: auto;
		flex-basis: auto;
	}

	.header-social-list {
		display: none;
		margin-right: 0;
	}

	.header-social-list-item {
		max-width: 115px;
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
	}

	.hero-image-wrapper {
		height: 50vh;
		margin-top: 175px;
	}

	.hero-inner-content {
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.partners-block {
		width: 225px;
	}

	.partners-wrapper {
		padding-right: 3rem;
		padding-left: 3rem;
	}

	.home-content-box {
		width: 100%;
		height: auto;
		margin-bottom: 3rem;
	}

	.home-content-box.welcome {
		width: 100%;
		height: auto;
		margin-bottom: 0;
		padding: 0 2rem 3rem;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.home-content-box.winners {
		margin-bottom: 0;
	}

	.home-content-box.about {
		height: auto;
		margin-bottom: 0;
		padding-top: 3rem;
		padding-right: 0;
		padding-left: 0;
	}

	.mobile-menu {
		margin-left: auto;
		padding: 0.5rem 2rem 0.5rem 0;
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-ms-flex-order: 1;
		order: 1;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		background-color: transparent;
		color: #1c2d54;
		font-size: 25px;
	}

	.mobile-menu.w--open {
		padding-top: 0.5rem;
		padding-right: 2rem;
		padding-bottom: 0.5rem;
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-ms-flex-order: 1;
		order: 1;
		background-color: transparent;
	}

	.nav-menu {
		position: static;
		left: 0;
		top: 0;
		right: 0;
		bottom: auto;
		display: block;
		width: 100%;
		max-height: none;
		margin-top: 0;
		padding: 0;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-align-self: auto;
		-ms-flex-item-align: auto;
		-ms-grid-row-align: auto;
		align-self: auto;
		border-top: 1px none rgba(0, 0, 0, 0.05);
		background-color: #fff;
	}

	.nav-dd {
		display: none;
		margin-bottom: 0;
		padding-bottom: 0;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.dd-icon {
		position: static;
		bottom: auto;
		margin-top: 0;
		margin-bottom: 0;
		margin-left: 0;
		-webkit-align-self: auto;
		-ms-flex-item-align: auto;
		-ms-grid-row-align: auto;
		align-self: auto;
	}

	.nav-toggle {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-left: 0;
		padding-top: 1.25rem;
		padding-bottom: 1.25rem;
		padding-left: 2rem;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		color: #373a3d;
	}

	.nav-dd-list {
		position: relative;
		background-color: transparent;
		box-shadow: none;
	}

	.dd-link {
		padding-left: 0;
		color: #fff;
	}

	.nav-menu-link {
		display: none;
		width: 100%;
		margin-left: 0;
		padding: 1.25rem 2rem;
		color: #373a3d;
		font-weight: 400;
	}

	.menu-container {
		margin-right: 0;
		margin-left: 0;
		padding: 0;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-bottom-style: none;
	}

	.footer-grip-link.color-png {
		margin-top: 0;
		margin-bottom: 0;
		margin-left: 0;
	}

	.content-image {
		height: 200px;
	}

	.content-wrapper {
		height: auto;
		padding: 1rem;
	}

	.content-wrapper.giveaway {
		height: auto;
		padding: 0;
	}

	.home-content {
		max-width: 100%;
		margin-bottom: 0;
		-webkit-flex-basis: auto;
		-ms-flex-preferred-size: auto;
		flex-basis: auto;
	}

	.menu-icon {
		float: left;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
		color: #333;
		font-size: 2rem;
		line-height: 2rem;
	}

	.nav-menu-mobile-link {
		display: block;
		border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	}

	.nav-menu-mobile-link:hover {
		background-color: rgba(91, 99, 107, 0.03);
	}

	.two-part-mobile-nav-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-top: 1px none #000;
		border-bottom: 1px solid rgba(77, 76, 76, 0.25);
	}

	.two-part-mobile-nav-wrapper.top {
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-top-style: none;
		border-top-color: rgba(77, 76, 76, 0.25);
		border-bottom-style: none;
		border-bottom-color: rgba(77, 76, 76, 0.25);
		font-family: Montserrat, sans-serif;
	}

	.two-part-mobile-nav-wrapper.top:hover {
		background-color: rgba(91, 99, 107, 0.03);
	}

	.main-nav-link {
		border-bottom: 1px solid rgba(0, 0, 0, 0.2);
		text-align: left;
	}

	.main-nav-link.mobile-duplicate {
		display: block;
		width: 88%;
		padding: 20px;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-bottom-style: none;
		background-color: transparent;
		font-family: 'Open Sans', sans-serif;
		color: #373a3d;
		font-size: 1rem;
		font-weight: 400;
	}

	.main-nav-link.mobile-duplicate:hover {
		background-color: transparent;
		background-image: none;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}

	.main-nav-link.mobile-duplicate.w--current {
		box-shadow: inset 6px 0 0 0 #f9b604;
		-webkit-transform: scale(1);
		-ms-transform: scale(1);
		transform: scale(1);
		color: #f9b604;
	}

	.mm-btn.mm-btn_next,
	.mobile-menu-arrow {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 12%;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-left: 1px solid rgba(77, 76, 76, 0.25);
		background-color: #1f79be;
		color: #000;
	}

	.mm-btn.mm-btn_next,
	.mobile-menu-arrow:hover {
		background-color: #1154a2;
	}

	.mobile-arrow {
		margin-right: 1.25rem;
		margin-left: 1.25rem;
		font-family: Montserrat, sans-serif;
		color: #fff;
		font-size: 1rem;
		line-height: 1em;
		font-weight: 500;
	}

	.secondary-nav-list-item-2 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-left: 1rem;
		padding-top: 0;
		padding-bottom: 0;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		align-self: center;
		border-left: 1px none hsla(0, 0%, 100%, 0.15);
		border-radius: 0;
		background-color: #1f79be;
	}

	.secondary-nav-list-item-2:hover {
		background-color: #538300;
	}

	.footer-social-list-item-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-right: 0;
	}

	.footer-rights-wrapper {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.footer-rights-left-col {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.footer-grip-logo {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.cb-image-wrapper {
		width: 65%;
	}

	.block-quote {
		margin-top: 20px;
	}

	.horizontal-divider {
		margin-top: 1.5rem;
	}

	.footer-container {
		padding-top: 0;
		padding-right: 0;
		padding-left: 0;
	}
}

@media screen and (max-width: 767px) {
	body {
		font-size: 0.875rem;
	}

	h2 {
		font-size: 1.5em;
		line-height: 1.5em;
	}

	h3 {
		font-size: 1.375em;
		line-height: 1.5em;
	}

	h4 {
		font-size: 1.125em;
		line-height: 1.5em;
	}

	.mobile-friendly-image {
		display: block;
		float: none !important;
		margin-left: 0 !important;
		margin-right: 0 !important;
	}

	.primary,
	.primary-button {
		margin-top: 0;
		margin-bottom: 0;
	}

	.primary.header-search-button,
	.primary-button.header-search-button {
		width: 15%;
		height: 3.5rem;
	}

	.partners-container {
		padding-top: 0;
		padding-right: 0;
		padding-left: 0;
	}

	.partners-container.partners {
		padding-top: 0;
		padding-right: 0;
		padding-left: 0;
	}

	.partners-container.main-content {
		padding-top: 10px;
	}

	.footer-section {
		padding-top: 2rem;
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.row.footer-row {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-right: 0;
		margin-left: 0;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.copyright-text {
		text-align: left;
	}

	.footer-signoff-list {
		display: block;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.left-footer-wrapper {
		max-width: 50%;
		margin-right: 2rem;
		padding-right: 0;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.left-footer-wrapper.logo-col {
		margin-right: 0;
	}

	.header-content-right-wrap {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.form-field.header-search-field {
		width: 85%;
		height: 3.5rem;
		-webkit-flex-basis: 80%;
		-ms-flex-preferred-size: 80%;
		flex-basis: 80%;
	}

	.form-field.header-search-field:focus {
		width: 85%;
	}

	.secondary-nav-list {
		margin-left: 1rem;
	}

	.main-content-column {
		padding-right: 0;
		padding-left: 0;
	}

	.inside-row-nested {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.right-sidebar-column {
		margin-top: 0;
		padding-top: 0;
	}

	.sidebar-section-wrap {
		margin-bottom: 0.25em;
		margin-left: 0;
	}

	.section-wrap.inside-content-section {
		padding-right: 0;
		padding-left: 0;
	}

	.home-content-box-wrap.home-news-cbox {
		width: auto;
		margin: 0 10px;
		float: none;
	}

	.hero-image {
		position: relative;
		height: 55vh;
	}

	.hero-image.banner {
		background-position: 0 0px, 50% 50%;
	}

	.inside-page-header-content-wrap {
		margin-right: 0;
		margin-left: 0;
	}

	.inside-page-header-section {
		min-height: 90px;
		margin-right: 0;
		margin-bottom: 0;
		margin-left: 0;
	}

	.inside-content-beckground {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.main-content-wrap.no-sidebars {
		margin-right: 10px;
		margin-left: 10px;
	}

	.intro-text-style {
		font-size: 1.125em;
	}

	.hero-image-title {
		max-width: 400px;
		font-size: 2.25rem;
		line-height: 2.4rem;
		text-align: center;
	}

	.header-container {
		padding: 0.5rem 1rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.secondary,
	.secondary-button {
		margin-left: 0;
		padding-left: 1.75rem;
		-webkit-box-ordinal-group: 1;
		-webkit-order: 0;
		-ms-flex-order: 0;
		order: 0;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		background-image: none;
		background-size: auto;
		background-repeat: repeat;
	}

	.content-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-bottom: 1.5rem;
		padding-right: 3rem;
		padding-left: 3rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
		text-shadow: none;
	}

	.header-social-icon-link.facebook {
		margin-right: 0;
	}

	.header-social-icon-link.twitter {
		margin-right: 0;
	}

	.header-social-icon-image {
		width: 2.25rem;
		height: 2.5rem;
		margin-right: 0.5rem;
	}

	.footer-social-list-item {
		max-width: none;
		padding-right: 0;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.header-social-list {
		display: none;
	}

	.header-social-list-item {
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
	}

	.hero-image-wrapper {
		height: 55vh;
		margin-top: 140px;
	}

	.hero-inner-content {
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.partners-block {
		width: 200px;
	}

	.partners-block.ascensus {
		width: 300px;
	}

	.paragraph {
		display: block;
	}

	.home-content-box.welcome {
		padding-right: 0;
		padding-bottom: 3rem;
		padding-left: 0;
	}

	.home-content-box.about {
		padding-right: 0;
		padding-left: 0;
	}

	.mobile-menu {
		padding-top: 0.5rem;
		padding-right: 1rem;
		padding-bottom: 0.5rem;
	}

	.mobile-menu.w--open {
		padding-right: 1rem;
	}

	.nav-menu {
		overflow: auto;
		width: 100%;
		max-height: 94vh;
	}

	.nav-menu-link {
		margin-right: 0;
	}

	.menu-icon {
		display: block;
		height: auto;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		font-size: 2rem;
		line-height: 2rem;
	}

	.main-nav-link.mobile-duplicate {
		width: 84%;
		padding-left: 20px;
	}

	.mm-btn.mm-btn_next,
	.mobile-menu-arrow {
		background-color: #1f79be;
	}

	.secondary-nav-list-item-2 {
		max-width: 100%;
		-webkit-box-flex: 1;
		-webkit-flex: auto;
		-ms-flex: auto;
		flex: auto;
		background-color: #1f79be;
	}

	.footer-social-list-item-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}

	.footer-col {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.top-header-container {
		padding: 0.25rem 1rem;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.footer-rights-wrapper {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.footer-rights-left-col {
		margin-bottom: 0;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.footer-grip-logo {
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.block-quote {
		margin-top: 20px;
		font-size: 1.125rem;
		line-height: 1.5rem;
	}

	.horizontal-divider {
		margin-top: 1.5rem;
	}

	.pagination-middle-wrap-2 {
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.div-block-20 {
		margin-top: 40px;
	}

	.footer-container {
		padding-top: 0;
		padding-right: 0;
		padding-left: 0;
	}
}

@media screen and (max-width: 479px) {
	.primary.header-search-button,
	.primary-button.header-search-button {
		width: 20%;
	}

	.partners-container {
		display: block;
		padding-top: 0;
		padding-right: 0;
		padding-left: 0;
	}

	.partners-container.partners {
		padding-top: 0;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.partners-container.main-content {
		padding-top: 30px;
	}

	.footer-section {
		padding-top: 2rem;
		padding-right: 2rem;
		padding-left: 2rem;
		text-align: center;
	}

	.row.footer-row {
		margin-right: 0;
		margin-left: 0;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.footer-signoff-section {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-bottom: 1rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.copyright-text {
		text-align: center;
	}

	.footer-signoff-list {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.footer-signoff-list-item {
		margin-bottom: 0.25rem;
		border-left-style: none;
	}

	.footer-signoff-list-item.adobe-acrobat {
		margin-bottom: 0.25rem;
		border-left-style: none;
	}

	.left-footer-wrapper {
		max-width: 100%;
		margin-top: 1rem;
		margin-right: 0;
		padding-right: 0;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-flex-basis: 100%;
		-ms-flex-preferred-size: 100%;
		flex-basis: 100%;
	}

	.left-footer-wrapper.logo-col {
		max-width: 100%;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.header-content-right-wrap {
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.breadcrumb-list {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.breadcrumb-list-item {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-right: 0;
		-webkit-box-align: end;
		-webkit-align-items: flex-end;
		-ms-flex-align: end;
		align-items: flex-end;
	}

	.form-field.header-search-field {
		width: 80%;
	}

	.form-field.header-search-field:focus {
		width: 80%;
	}

	.secondary-nav-list {
		max-width: none;
		margin-left: 0.75rem;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.breadcrumb-divider {
		margin-right: 0.5rem;
	}

	.right-sidebar-column {
		margin-top: 0;
		padding-top: 0;
	}

	.sidebar-section-wrap {
		margin-bottom: 0.25em;
	}

	.home-content-box-wrap {
		margin-bottom: 1rem;
		-webkit-flex-basis: 100%;
		-ms-flex-preferred-size: 100%;
		flex-basis: 100%;
	}

	.home-content-box-wrap.home-news-cbox {
		margin-right: 0;
		margin-left: 0;
	}

	.home-content-box-title {
		font-size: 2rem;
		line-height: 2.15rem;
		text-align: center;
	}

	.home-content-box-title.giveaways {
		font-size: 2rem;
		line-height: 2.15rem;
	}

	.hero-image.banner {
		height: 50vh;
		align-items: center;
		background-position: 0 0px, 50% 50%;
		background-size: auto, cover;
		background-repeat: repeat, no-repeat;
	}

	.inside-content-beckground {
		margin-top: 150px;
	}

	.main-content-wrap.nested-content-wrap {
		margin-right: 0;
	}

	.intro-text-style {
		margin-bottom: 0.5em;
	}

	.hero-image-title {
		max-width: none;
		font-size: 1.8rem;
		line-height: 2.15rem;
		text-align: center;
	}

	.link.breadcrumb-link {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.link.home-news-article-title-link {
		font-size: 1.5rem;
		line-height: 1.75rem;
	}

	.header-container {
		padding-right: 0.75rem;
		padding-bottom: 0.5rem;
		padding-left: 0.75rem;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.secondary,
	.secondary-button {
		width: 100%;
	}

	#registerBtn {
		display: none;
	}

	.center-align {
		text-align: center;
	}

	.secondary.mobile,
	.secondary-button.mobile {
		display: block;
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.secondary.button-styles,
	.secondary-button.button-styles {
		display: block;
		width: auto;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.winners-category-row {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.winners-category-item-text-wrap {
		margin-top: 1em;
	}

	.content-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-bottom: 1.5rem;
		padding-right: 0.5rem;
		padding-left: 0.5rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-align-self: auto;
		-ms-flex-item-align: auto;
		align-self: auto;
	}

	.home-intro-section {
		padding: 1rem;
	}

	.home-intro-section.news-and-winners {
		padding: 1rem;
	}

	.header-social-icon-link.facebook {
		margin-right: 0;
	}

	.header-social-icon-link.twitter {
		margin-right: 0;
	}

	.header-social-icon-image {
		width: 2.15rem;
		height: 2.5rem;
		margin-right: 0.25rem;
	}

	.home-news-article-title {
		text-align: center;
	}

	.home-news-article-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-top: 0;
		margin-bottom: 0;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.home-news-article-date {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		font-size: 0.75rem;
	}

	.intro-cbox-signature-label {
		width: 100%;
		-webkit-align-self: auto;
		-ms-flex-item-align: auto;
		-ms-grid-row-align: auto;
		align-self: auto;
		text-align: center;
	}

	.footer-social-list-item {
		margin-right: 0.5rem;
		padding-right: 0;
	}

	.header-social-list {
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.hero-image-wrapper {
		height: 50vh;
		margin-top: 120px;
	}

	.hero-inner-content {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.partners-block {
		width: 100%;
		margin-top: 1rem;
		margin-bottom: 1rem;
	}

	.partners-wrapper {
		padding: 2rem;
	}

	.paragraph {
		text-align: center;
	}

	.treasurer-logo-img {
		margin-right: 0;
		margin-bottom: 1rem;
	}

	.home-content-box.welcome {
		padding-bottom: 1rem;
	}

	.home-content-box.about {
		padding-top: 2rem;
	}

	.dd-text {
		text-align: center;
	}

	.mobile-menu {
		padding-right: 0.75rem;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.mobile-menu.w--open {
		padding-right: 0.75rem;
	}

	.nav-menu {
		max-height: none;
		margin-top: 0;
		padding-top: 0;
		padding-right: 0;
		padding-left: 0;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.nav-dd {
		margin-bottom: 10px;
	}

	.dd-icon {
		position: absolute;
		margin-top: 10px;
		margin-right: 20px;
		margin-left: 0;
	}

	.nav-toggle {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: 35px;
		padding-top: 10px;
		padding-right: 0;
		padding-bottom: 0;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.dd-link {
		padding-right: 0;
		text-align: center;
	}

	.nav-menu-link {
		display: none;
		width: 100%;
		height: 35px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		font-size: 0.875rem;
		line-height: 80%;
		text-align: center;
	}

	.nav-menu-link.w--current {
		border-bottom-style: solid;
		border-bottom-color: transparent;
		background-image: linear-gradient(135deg, #081a35, #22447a);
		color: #e8c18c;
	}

	.menu-container {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.link-block {
		width: auto;
	}

	.div-block-8 {
		width: 90%;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.pagination-previous-page.pagination-bg,
	.pagination-next-page.pagination-bg,
	#news_module a[href="/news"],
	.tertiary,
	.tertiary-button {
		bottom: auto;
		display: inline-block;
		margin-top: 1rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.footer-grip-link.color-png {
		margin-left: 0;
	}

	.content-image {
		height: 150px;
	}

	.content-wrapper {
		display: block;
		padding: 1rem 0 0 0;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.bio-image {
		margin-right: 0;
		margin-bottom: 0.5rem;
	}

	.home-content {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.bio-img-wrapper {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.menu-icon {
		display: block;
		float: left;
		line-height: 2rem;
	}

	.main-nav-link.mobile-duplicate {
		width: 80%;
	}

	.mobile-menu-arrow {
		position: relative;
		width: 20%;
	}

	.secondary-nav-list-item-2 {
		max-width: 100%;
		margin-left: 1.5rem;
		border-left-style: solid;
	}

	.footer-social-list-item-wrapper {
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.top-header-container {
		padding-right: 1rem;
		padding-bottom: 0.25rem;
		padding-left: 1rem;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.footer-rights-wrapper {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.footer-rights-left-col {
		padding-bottom: 1rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.footer-grip-logo {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.cb-image-wrapper {
		width: 100%;
		height: auto;
	}

	.h2-heading {
		line-height: 1.25em;
	}

	.paragraph-style {
		text-align: left;
	}

	.block-quote {
		margin-top: 20px;
	}

	.horizontal-divider {
		margin-top: 1.5rem;
	}

	.button-styling {
		width: auto;
		height: 150px;
		margin-bottom: 2rem;
		padding-left: 0;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.past-winners-date {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		font-size: 0.75rem;
	}

	.pagination-middle-wrap-2 {
		min-width: 100%;
		margin-top: 0.75em;
		padding-top: 0.75em;
		border-top: 1px solid rgba(77, 76, 76, 0.15);
	}

	.footer-container {
		display: block;
		padding-top: 0;
		padding-right: 0;
		padding-left: 0;
	}

	#GRIPFooterLogo {
		margin: auto;
	}
}

/* Set root font size */
html {
	font-size: 16px;
}

/* Set font render */
body {
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
}

/* Superscript */
sup {
	font-size: xx-small;
}

/* Clear input field styling */
input[type=text] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

/* Override input field placeholder color */
input::-webkit-input-placeholder {
	color: #ccc !important;
}

input::-moz-placeholder {
	color: #ccc !important;
}

input:-moz-placeholder {
	color: #ccc !important;
}

input:-ms-input-placeholder {
	color: #ccc !important;
}

/* Set divider style */
hr {
	color: #ccc;
	border-color: #ccc;
	border: 0px;
	border-top: 1px solid;
	margin-bottom: 1.5em;
	margin-top: 1.5em;
}

/* Adjust heading styles when they're set as links */
xh1 > a {
	color: inherit;
}

xh1 > a:hover {
	color: inherit;
}

x.w-slider-nav > div {
	border: white 3px solid;
	background: black;
}

x.w-slider-nav > div:hover {
	border: white 3px solid;
	background: black;
}

x.w-slider-nav > div.w-active {
	border: white 3px solid;
	background: white;
}

x.w-slider-nav > div.w-active:hover {
	border: white 3px solid;
	background: white;
}

/* Custom styles */
/* GRIP-UAT styles */
.success {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	border-left: 4px solid #006953;
	background-color: #afd8cf;
}

.error {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	border-left: 4px solid #d82b38;
	background-color: #f5d1d1;
}

table {
	font-family: Montserrat, sans-serif;
}

table.styled thead th,
table.styled tbody th,
table.styled th a,
table.styled.striped th a {
	font-weight: inherit;
	color: #fff;
	font-size: 1rem;
	line-height: 1.2rem;
	text-align: left;
}

table.styled td,
table.styled.striped td {
	font-size: 0.96rem;
	padding: 0.375rem 0.625rem;
}

table.styled th a:hover,
table.styled.striped th a:hover {
	text-decoration: none;
}

table.styled thead th {
	font-weight: inherit;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.5rem 0.625rem;
	text-align: left;
	background-color: #1154a2;
	opacity: 100%;
	color: white;
}

table.styled tbody th {
	font-weight: inherit;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.625rem 0.625rem;
	text-align: left;
	background-color: #1f79be;
	opacity: 100%;
	color: white;
}

table.styled.striped tr:nth-child(even) td {
	background: #dddddd;
}

table.styled.striped tr:nth-child(odd) td {
	background: #ffffff;
}

#submissionforms_module.cms_form .cms_buttons .cms_form_button {
	padding: 0.5em 1.75rem;
}
/* End GRIP-UAT styles */

.mm-wrapper_opened .nav-section.w-nav {
	position: sticky;
}

.mm-wrapper_opened .hero-image-wrapper,
.mm-wrapper_opened .is-inside-page .inside-content-beckground {
	margin-top: 0;
}

.mm-panel {
	background-color: #fff;
}

.mm-listitem:after {
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.mm-btn.mm-btn_next {
	border-left: 1px solid rgba(0, 0, 0, 0.05);
}

.top-header-nav-menu {
	margin: 0;
	padding: 0;
}

.top-header-nav-link {
	padding: 0.5rem 1.7rem;
	margin-bottom: 0;
}

.dropdown-menu {
	display: none;
	position: absolute;
}

.dropdown-menu ul,
li {
	margin: 0;
}

.dropdown-menu .dropdown-li {
	padding: 0.75rem 3rem 0.75rem 1rem;
}

.dropdown-li .dd-link {
	margin: 0;
}

.top-header-nav-link:hover .dropdown-menu {
	display: block;
	margin-top: -3px;
	border-top: 5px solid #ae332a;
	background-color: #dfdfdf;
	box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.05);
}

.nav-dd-list {
	list-style: none;
	padding-left: 0;
}

.dropdown-li:hover {
	background-color: rgba(91, 99, 107, 0.2);
}

.mm-btn.mm-btn_next.mm-listitem__btn::after {
	border-color: #fff;
}

#faqs-search {
	position: relative;
	width: auto;
	margin-bottom: 0.75em;
	padding: 4px 10px;
	font-size: 0.875em;
	border: 1px solid #ccc;
}

.span-on-desktop:hover {
	text-decoration: none;
}

.shrink-element-10-percent {
	transition: all 0.4s ease-in-out;
	transform: scale(0.9);
}

.shrink-element-20-percent {
	transition: all 0.4s ease-in-out;
	transform: scale(0.8);
}

.grow-element {
	transition: all 0.4s ease-in-out;
	transform: scale(1);
}

.reduce-padding {
	transition: all 0.4s ease-in-out;
	padding: 0 1.5rem;
}

/* Targets News module right sidebar titles */
.box.right-sidebar-box .title {
	margin-top: 40px;
}

#print-header,
#print-footer {
	display: none;
}

.main-content {
	min-height: 70vh;
}

#GRIPFooterLogo {
	margin-bottom: 20px;
}

.hasChildren::after {
	content: "\02EF";
	font-size: 40px;
	color: #373a3d;
	float: right;
	margin-top: -47px;
	margin-right: 6px;
}

.hasChildren:hover::after {
	flex: 0 0 auto;
	color: #ae332a;
}

.dd-link,
.main-nav-link {
	border-bottom: none;
}

.main-nav-link:hover {
	text-decoration: none;
}

.home-content-box-title.giveaways p,
.hero-image.banner .hero-image-title p {
	margin-bottom: 0;
}

.content-wrapper._2 {
	padding: 2rem;
}

.pagination-previous-page.pagination-bg span.disabled,
.pagination-previous-page.pagination-bg,
.pagination-next-page.pagination-bg {
	border-bottom: none;
}

.pagination-wrapper .pagination-previous-page .disabled,
.pagination-wrapper .pagination-next-page .disabled {
	font-style: normal;
}

.fly-out-menu-container .fly-out-menu-button {
	border: 1px solid #3f3f3f;
	background-color: rgba(77, 76, 76, 0.03);
}

#news_module .cms_title h3 a {
	text-decoration: underline;
	text-decoration-color: rgba(3, 56, 108, 0.25);
	text-decoration-thickness: 1px;
	text-underline-offset: 6px;
	border-bottom: none;
}

.phone-number,
.footer-link {
	border-bottom: none;
}

.left-nav-list-link {
	border-bottom: none;
}

@media screen and (max-width: 767px) {
	.hero-image-wrapper {
		margin-top: 166px;
	}
}
/* End custom styles */
